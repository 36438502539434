import React, { useMemo } from 'react';
import { Card, Button, Input, NewButton, Checkbox, AgGridTable } from 'components';
import moment from 'moment';
import { LiaEyeSolid } from 'react-icons/lia';

const Log = ({ _this }) => {
  const customer = _this.customerDetails;

  const logs_table_columns = [
    {
      headerName: 'Date',
      cellRenderer: (row) => <div>{moment(row.data.createdAt).format('YYYY-MM-DD, h:mm a')}</div>
    },
    {
      field: 'action',
      headerName: 'Action',
      cellRenderer: (row) => <div>{row.data.action.replace(/([a-z])([A-Z])/g, '$1 $2')}</div>
    },
    {
      field: 'executed_by',
      headerName: 'By',
      cellRenderer: (row) => (
        <div>{row.data.executed_by == 'Customer' ? 'Self' : row.data.employee.name}</div>
      )
    },
    {
      field: 'data',
      headerName: 'Data',
      cellRenderer: (row) => {
        return (
          <div className="flex justify-center">
            {row?.data?.data ? (
              row.data.action.includes('Email') ? (
                <LiaEyeSolid
                  size={25}
                  className="text-body-color cursor-pointer"
                  onClick={() => {
                    _this.setSeeLogEmailDataModalOpen(true),
                      _this.setLogEmailData((prev) => ({
                        ...prev,
                        _id: row?.data?._id,
                        data: row?.data?.data,
                        createdAt: row?.data?.createdAt
                      }));
                  }}
                />
              ) : (
                <LiaEyeSolid
                  size={25}
                  className="text-body-color cursor-pointer"
                  onClick={() => {
                    _this.setSeeLogDataModalOpen(true);
                    _this.setLogDataToShow({
                      action: row?.data?.action,
                      data: row?.data?.data
                    });
                  }}
                />
              )
            ) : null}
          </div>
        );
      }
    }
  ];
  return (
    <Card className="rounded-xl w-full md:w-[68%]" paddingClass="p-3">
      <h3 className="text-xl font-bold pb-2">Logs</h3>
      {/* <div className="ag-theme-alpine" style={{ height: '70vh' }}> */}
      <AgGridTable
        className="h-[70vh] border-2 bg-slate-400"
        //   reference={_this.gridRef}
        rowData={customer.logs}
        columnDefs={logs_table_columns}
        masterDetail={true}
        fitToScreen={true}
        detailRowHeight={310}
        autoResizeColumn={true}
        stopEditingWhencellsLoseFocus={true}
        border={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20]}
        defaultColDef={useMemo(() => ({
          flex: 1
        }))}
      />
      {/* </div> */}
    </Card>
  );
};

export default Log;
