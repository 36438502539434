import React, { useState, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useWindowDimensions } from '../hooks';
import classNames from 'classnames';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

/**
 * AgGridTable is a component created on top of AgGridReact
 * {object} props Other Component props for AgGridReact
 * {string} className className to the parent wrapper element
 * {object} reference Reference to the element
 * {object} columnDefs Column defination for the table
 * {object} defaultColDef Overwrite Default Column Defination
 * {bool} smallRow True for small row size or False for big row size. Default is true.
 * {bool} fitToScreen True to fit the table to screen. Default is false.
 * {bool} autoResizeColumn True to fit column size to content. Default is true.
 * {bool} border True to add right border to each cell except the last. Default is true.
 * {bool} isLoading True to show loader and False to hide loader. Default is false.
 * {bool} resizeOnDataChange True to resize table on data change, false to keep the size intact even after data change. Default is true.
 */
const AgGridTable = ({
  className,
  reference = null,
  rowData,
  columnDefs,
  //   defaultColDef = useMemo(() => ({
  //     flex: 1
  //   })),
  defaultColDef = null,
  smallRow = false,
  fitToScreen = false,
  autoResizeColumn = true,
  resizeOnDataChange = true,
  border = true,
  isLoading = false,
  onTableReady = () => {},
  ...props
}) => {
  // console.log(rowData);
  const { height, width } = useWindowDimensions();
  const clientWidth = useRef();
  const [_defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    resizable: true,
    // filter: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    wrapText: false
    // cellRenderer: (props) => (
    //   <div className="flex justify-center items-center whitespace-normal break-normal leading-tight">
    //     {String(props.value)}
    //   </div>
    // )
  });
  const [_columnDefs, setColumnDefs] = useState(columnDefs);

  useEffect(() => {
    if (defaultColDef != null) overwriteProp(setDefaultColDef, defaultColDef);
  }, [defaultColDef]);

  useEffect(
    () => {
      if (rowData && rowData.length > 0) {
        if (autoResizeColumn) {
          setTimeout(() => {
            autoSizeColumns();
          }, 3000);
        }
      }
    },
    resizeOnDataChange ? [rowData] : []
  );

  useEffect(() => {
    if (border) enableBorder();
  }, []);

  useEffect(() => {
    if (isLoading) reference?.current?.api?.showLoadingOverlay();
    else reference?.current?.api?.hideOverlay();
  }, [isLoading]);

  const overwriteProp = (setStateKey, newValues) => {
    if (newValues.wrapText && newValues.wrapText == true) {
      newValues.cellRenderer = (props) => (
        <div className="whitespace-normal break-normal">{String(props.value)}</div>
      );
    }
    setStateKey((prev) => ({ ...prev, ...newValues }));
  };

  const enableBorder = () => {
    const temp = columnDefs.map((item, index) => {
      if (index != columnDefs.length - 1) {
        if (item.cellClass) {
          item.cellClass = 'right-bordered-cell ' + item.cellClass;
        } else {
          item.cellClass = 'right-bordered-cell';
        }
      }
      return item;
    });
    setColumnDefs(temp);
  };

  const resizeToFitScreen = () => {
    reference?.current?.api?.sizeColumnsToFit();
  };

  const autoSizeColumns = () => {
    // Resize all columns to header and content
    const allColumnIds = [];
    reference?.current?.columnApi?.getColumns()?.forEach((column) => {
      if (!column?.colDef?.width) allColumnIds.push(column.getId());
    });
    reference?.current?.columnApi?.autoSizeColumns(allColumnIds, false);
    //reference?.current?.columnApi?.autoSizeAllColumns(false);
    const usedSpace = reference?.current?.columnApi
      ?.getColumns()
      ?.reduce((sum, column) => sum + column.actualWidth, 0);
    // Fit to screen if there is space left
    if (clientWidth.current > usedSpace) resizeToFitScreen();
  };

  const onFirstDataRendered = () => {
    if (autoResizeColumn) autoSizeColumns();
  };

  const onGridSizeChanged = (e) => {
    clientWidth.current = e.clientWidth;
    const usedSpace = reference?.current?.columnApi
      ?.getColumns()
      ?.reduce((sum, column) => sum + column.actualWidth, 0);
    // Fit to screen if there is space left
    if (clientWidth.current > usedSpace) resizeToFitScreen();
  };

  const onGridReady = () => {
    if (fitToScreen) resizeToFitScreen();
    onTableReady();
  };

  return (
    <div
      className={classNames('ag-theme-alpine', className, 'text-black font-normal custom-ag-grid')}
    >
      <AgGridReact
        ref={reference} // Ref for accessing Grid's API
        rowData={rowData} // Row Data for Rows
        columnDefs={_columnDefs} // Column Defs for Columns
        defaultColDef={_defaultColDef} // Default Column Properties
        rowHeight={smallRow ? 30 : 40}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onGridSizeChanged={onGridSizeChanged}
        suppressColumnVirtualisation
        {...props}
      />
    </div>
  );
};

export default AgGridTable;
