import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';
import { FaPlus, FaPlusCircle, FaPlusSquare } from 'react-icons/fa';
import { BiPlus, BiSend } from 'react-icons/bi';
import { GrFormClose } from 'react-icons/gr';
import { MdDelete } from 'react-icons/md';
import { PermissionWrapper, HasPermission } from 'components';

const DocumentModel = ({ _this }) => {
  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    _this.setFileAttachment(selectedFile);
  };

  return (
    <Modal
      style={{ zIndex: 9999 }}
      className="mt-4"
      show={_this.chatModalOpen}
      onHide={() => {
        _this.setChatModalOpen(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="flex justify-between">
        <Modal.Title id="contained-modal-title-vcenter" className="font-bold text-md">
          Documents
        </Modal.Title>
        <div className="flex gap-2 items-center">
          <h3 className="text-md">
            Patient Name :
            <strong>
              {_this.customerDetails?.first_name + ' ' + _this.customerDetails.last_name}
            </strong>
          </h3>
          <AiFillCloseCircle
            size={30}
            onClick={() => {
              _this.setChatModalOpen(false);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="m-0">
        <Card className="flex  border-[1px] border-blue-500 mb-1 overflow-hidden">
          <div className="min-h-[60vh] max-h-[60vh] flex flex-col hide-scrollbar overflow-y-scroll">
            <div
              className="self-start bg-blue-500
                   text-white rounded p-1 mb-2 max-w-[80%] flex flex-co"
            >
              <div className="flex flex-col">
                Term and Conditions
                <a
                  className="text-xs p-2"
                  target="_blank"
                  href="https://myhspp.s3.amazonaws.com/prod/1694758636980.docx"
                  rel="noreferrer"
                >
                  Download Term and Conditions
                </a>
              </div>
            </div>
            {_this.documents?.map((document, i) => {
              return (
                <div
                  className={`${
                    // checking if editor is customer
                    document.employee ==
                    `${_this.customerDetails?.first_name} ${_this.customerDetails?.last_name}`
                      ? 'self-start bg-blue-500'
                      : 'self-end bg-green-500'
                  } text-white rounded p-1 mb-2 max-w-[80%] flex flex-col`}
                  key={i}
                >
                  <div className="flex flex-col">
                    <PermissionWrapper permission={'delete_document'}>
                      <div
                        className="text-red-800 self-end cursor-pointer"
                        onClick={() => {
                          _this.setDeleteDocumentModal(true),
                            _this.setDeleteDocumentId(document._id);
                        }}
                      >
                        <MdDelete size={20} />
                      </div>
                    </PermissionWrapper>

                    <p>{document?.message}</p>
                    <a
                      className="text-xs text-right"
                      target="_blank"
                      href={document?.file_url}
                      rel="noreferrer"
                    >
                      Download Document
                    </a>
                    <div className="text-xs flex flex-row gap-2 ">
                      <p>{document.employee}</p>
                      <p>{moment(document.createdAt).format('DD-MM-YYYY HH:MM:SS')}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row justify-center items-center border-[1px] border-blue-500 relative">
            {_this.fileAttachment ? (
              <p className="absolute -top-6 left-0 flex justify-center bg-green-500 text-white pl-2 rounded-lg overflow-hidden max-w-xs whitespace-nowrap">
                <span className="text-xs mr-2 overflow-hidden">{_this?.fileAttachment?.name}</span>
                <button
                  onClick={() => _this.setFileAttachment(null)}
                  className="text-white border-l-2 border-gray-500 pr-1 cursor-pointer hover:bg-green-400"
                >
                  <GrFormClose size={15} />
                </button>
              </p>
            ) : null}
            <button onClick={handleButtonClick} className="bg-blue-500 text-white">
              <BiPlus size={40} />
            </button>
            <input
              id="fileInput"
              type="file"
              required
              className="hidden"
              onChange={handleFileChange}
            />
            <input
              type="text"
              required
              className="w-full border-none"
              placeholder="Type Message Here..."
              value={_this.userMessage}
              onChange={(e) => {
                _this.setUserMessage(e.target.value);
              }}
            />
            <BiSend
              onClick={_this.handelChatMessageSend}
              size={40}
              className="bg-blue-500 text-white px-2"
            />
          </div>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default DocumentModel;
