import React from 'react';
import { Card, Button, Input, NewButton, Checkbox } from 'components';
import { PermissionWrapper, HasPermission } from 'components';

const Notes = ({ _this }) => {
  const customer = _this.customerDetails;
  return (
    <Card className="rounded-xl w-full md:w-[33%]" paddingClass="p-2">
      <div className="p-2 flex flex-row items-center justify-between">
        <h3 className="text-xl font-bold pb-2">
          Notes
          <span className="ml-1 w-8 h-8 py-1 px-2 rounded-full bg-[#EFF8FF] text-[#175CD3] text-xs font-bold">
            {customer.notes.length}
          </span>
        </h3>
        <PermissionWrapper permission={'add_note'}>
          <NewButton
            onClick={() => {
              _this.setAddNoteModalOpen(true);
            }}
          >
            <span className="font-medium text-base ml-0.5 text-white">Add Note</span>
          </NewButton>
        </PermissionWrapper>
      </div>
      <div className="px-2">
        {customer?.notes.map((e, i) => (
          <div
            key={i}
            className="mt-3 p-3 rounded-xl flex flex-col justify-start items-start gap-2 border"
          >
            <p className="text-slate-500 text-sm">{e.by}</p>
            <p className="text-sm font-bold">{e.note}</p>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Notes;
