import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Input, NewButton, Checkbox, AgGridTable } from 'components';
import { PermissionWrapper, HasPermission } from 'components';
import { Tooltip, OverlayTrigger, ProgressBar } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { FcShipped, FcInfo } from 'react-icons/fc';
import { FaCheckCircle } from 'react-icons/fa';
import { TbFileInvoice } from 'react-icons/tb';
import { IoMdTime } from 'react-icons/io';
import { BiEditAlt } from 'react-icons/bi';
import moment from 'moment';
import {
  parseHsppId,
  renderStatusColorClass,
  renderInvoiceStatusColorClass
} from 'services/helpers';
import { HiOutlineReceiptRefund } from 'react-icons/hi2';
import { LiaCcPaypal } from 'react-icons/lia';

const Invoice = ({ _this }) => {
  const customer = _this.customerDetails;

  const tooltipUI = (row) => {
    if (row.data.status === 'Failed') {
      return (
        <div>
          {JSON.parse(row.data?.payment_gateway_response)?.last_payment_error?.message ||
            JSON.parse(row.data?.payment_gateway_response)?.message ||
            JSON.parse(row.data?.payment_gateway_response)?.failure_message}
        </div>
      );
    } else if (row.data.status === 'Paid' || row.data.status === 'Refunded') {
      const info = row.data?.refunded_data?.map((item, i) => {
        return (
          <div key={i}>
            <p>
              {moment(item.refunded_on).format('YYYY-MM-DD')} | ${item.amount} | {item.status}
            </p>
            <p>--{item.reason ? item.reason : ''}--</p>
          </div>
        );
      });
      return info;
    }

    return null;
  };

  const invoice_table_columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      cellRenderer: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            row.data.status === 'Failed' ||
            row.data.status === 'Refunded' ||
            row.data.status === 'Paid' ? (
              <Tooltip id="tooltip-bottom ">{tooltipUI(row)}</Tooltip>
            ) : (
              <></>
            )
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              className={`font-semibold px-2 py-1 rounded-full text-xs 
                ${renderInvoiceStatusColorClass(
                  row.data.status === 'Paid' || row.data.status === 'ChargebackWon'
                    ? 'Paid'
                    : row.data.status === 'Refunded'
                    ? 'Refunded'
                    : row.data.status === 'Processing'
                    ? 'Processing'
                    : 'Failed'
                )}
              `}
            >
              {row.data.status === 'Paid' ? (
                'PAID'
              ) : row.data.status === 'Failed' ? (
                'FAILED'
              ) : row.data.status === 'Void' ? (
                'CANCELLED'
              ) : row.data.status === 'Cancel' ? (
                'CANCELLED'
              ) : row.data.status === 'Processing' ? (
                'PROCESSING'
              ) : row.data.status === 'Refunded' ? (
                'REFUNDED'
              ) : row.data.status === 'Chargeback' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <IoMdTime size={18} />
                </div>
              ) : row.data.status === 'ChargebackWon' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <FaCheckCircle size={18} />
                </div>
              ) : row.data.status === 'ChargebackLost' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <FaTimes size={18} />
                </div>
              ) : (
                'PENDING'
              )}
            </div>
          )}
        </OverlayTrigger>
      )
    },

    {
      field: 'due_date',
      headerName: 'Due Date',
      width: 120,
      cellRenderer: (row) => (
        <div className="flex gap-2 justify-center">
          <span>{row.data.due_date && moment(row.data.due_date).format('YYYY-MM-DD')}</span>
          <PermissionWrapper permission={'edit_due_date'}>
            {row.data.status == 'Pending' && (
              <span>
                <BiEditAlt
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    _this.handleDueDateEdit(row.data._id, row.data.due_date);
                  }}
                />
              </span>
            )}
          </PermissionWrapper>
        </div>
      )
    },
    {
      headerName: 'Payment Method',
      width: 120,
      cellRenderer: (row) =>
        row.data.payment_gateway_transaction_id === 'credit' ? (
          <div>Credits</div>
        ) : (
          row.data?.payment_details?.payment_method && (
            <div>
              {row.data?.payment_details?.payment_method == 'check'
                ? 'Check : **' + row.data?.payment_details?.ach_details?.account_last_4_digit
                : 'Card : **' + row.data?.payment_details?.card_details?.card_last_4_digit}
            </div>
          )
        )
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      cellRenderer: (row) => <div className="font-bold">${row.data.amount}</div>
    },
    {
      headerName: 'Transaction ID',
      field: 'payment_gateway_transaction_id',
      minWidth: 260,

      cellRenderer: (row) =>
        row.data.payment_gateway_transaction_id && (
          <div className="text-sm text-body-color">{row.data.payment_gateway_transaction_id}</div>
        )
    },

    {
      field: 'paid_on',
      headerName: 'Payment Process Date',
      width: 120,
      cellRenderer: (row) => (
        <div className="flex gap-2 justify-center">
          <span>
            {row.data.status === 'Refunded'
              ? moment(row?.data?.refunded_data[0]?.refunded_on).format('YYYY-MM-DD')
              : row.data.paid_on && moment(row.data.paid_on).format('YYYY-MM-DD')}
          </span>
        </div>
      )
    },

    {
      cellRenderer: (row) => (
        <center>
          {row.data.status === 'Paid' ||
          row.data.status === 'Processing' ||
          row.data.status === 'ChargebackWon' ? (
            <PermissionWrapper permission="refund_invoices">
              <button
                className="bg-transparent border-rose-800 border-[1px] text-rose-800 px-2 py-1 rounded text-xs flex flex-row gap-2 self-center w-20 justify-center items-center"
                onClick={() => _this.handleRefundDetails(row.data)}
              >
                <HiOutlineReceiptRefund size={14} /> Refund
              </button>
            </PermissionWrapper>
          ) : (
            row.data.status === 'Failed' && (
              <PermissionWrapper permission="pay_failed_invoices">
                <button
                  className="bg-transparent border-blue-500 border-[1px] text-blue-500 px-2 py-1 rounded text-xs flex flex-row gap-2 self-center w-20 justify-center items-center"
                  onClick={() => _this.handleRetryPayment(row.data._id)}
                >
                  <LiaCcPaypal size={14} /> Pay
                </button>
              </PermissionWrapper>
            )
          )}
        </center>
      ),
      headerName: 'Refund/Pay',
      width: 150
    },
    {
      headerName: 'Actions',
      width: 150,
      cellRenderer: (row) => (
        <div className="py-2 flex gap-2 px-2 justify-center items-center">
          <div className="flex justify-center items-center">
            {HasPermission({ permission: ['delete_invoices'], require_all: false }) ? (
              <button
                className="text-red-500"
                onClick={() => _this.handleDeleteInvoices(row.data._id)}
              >
                <MdDelete size={18} />
              </button>
            ) : null}
          </div>

          {row?.data?.payment_gateway_response &&
            JSON.parse(row.data.payment_gateway_response)?.outcome?.seller_message && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom ">
                    <p>{JSON.parse(row.data.payment_gateway_response)?.outcome?.seller_message}</p>
                    <p></p>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">
                  <FcInfo size={18} />
                </span>
              </OverlayTrigger>
            )}
          {row.data.is_migrated_from_fusebill == true && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-bottom ">
                  <p>--Migrated--</p>
                  <p>Fusebill ID: {row.data.fusebill_id}</p>
                </Tooltip>
              }
            >
              <span className="cursor-pointer">
                <FcShipped size={18} />
              </span>
            </OverlayTrigger>
          )}
          <p>
            <Link
              to={`/view-invoice/${row.data._id}`}
              target="_blank"
              className="text-blue-500 underline"
            >
              {/* #{parseHsppId(row.data._id)} */}
              <TbFileInvoice size={18} />
            </Link>
          </p>
        </div>
      )
    }
  ];

  if (HasPermission({ permission: ['refund_duplicate_invoices'], require_all: false }))
    invoice_table_columns.push({
      cellRenderer: (row) => (
        <center>
          <button
            className="bg-transparent border-rose-800 border-[1px] text-rose-800 px-2 py-1 rounded text-xs flex flex-row gap-2 self-center justify-center items-center"
            onClick={() => _this.handleRefundDuplicateInvoiceDetails(row.data)}
          >
            <HiOutlineReceiptRefund size={14} /> Refund Duplicates
          </button>
        </center>
      ),
      headerName: 'Refund Duplicate Invoices'
    });
  return (
    <Card className="rounded-xl w-full md:w-[68%]" paddingClass="p-3">
      <h3 className="text-xl font-bold pb-2">Invoice</h3>
      {/* <div className="ag-theme-alpine" style={{ height: '70vh' }}> */}
      <AgGridTable
        className="h-[70vh] border-2 bg-slate-400"
        //   reference={_this.gridRef}
        rowData={customer.invoices}
        columnDefs={invoice_table_columns}
        masterDetail={true}
        fitToScreen={true}
        detailRowHeight={310}
        autoResizeColumn={true}
        stopEditingWhenCellsLoseFocus={true}
        border={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20]}
      />
      {/* </div> */}
    </Card>
  );
};

export default Invoice;
