import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Input, NewButton, Checkbox, AgGridTable } from 'components';
import { PermissionWrapper, HasPermission } from 'components';
import { Tooltip, OverlayTrigger, ProgressBar } from 'react-bootstrap';
import { FcInfo } from 'react-icons/fc';
import moment from 'moment';
import Config from 'config';
const stripe_link =
  Config?.ENVIRONMENT == 'DEV'
    ? 'https://dashboard.stripe.com/test/disputes/'
    : 'https://dashboard.stripe.com/disputes/';

const Chargeback = ({ _this }) => {
  const customer = _this.customerDetails;

  const chargeback_table = [
    {
      headerName: 'Dispute On',
      sortable: false,
      width: 155,
      cellRenderer: (row) => (
        <div>
          {row?.data?.data &&
            (JSON?.parse(row?.data?.data)?.created
              ? moment.unix(JSON?.parse(row?.data?.data)?.created)?.format('YYYY-MM-DD')
              : '--')}
        </div>
      )
    },
    {
      headerName: 'Dispute',
      field: 'dispute_id',
      width: 100,
      cellRenderer: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              <p>{row.data.dispute_id}</p>
            </Tooltip>
          }
        >
          <Link
            to={stripe_link + row.data.dispute_id}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 font-medium"
          >
            link
          </Link>
        </OverlayTrigger>
      )
    },
    {
      headerName: 'Transaction ID',
      sortable: false,
      width: 260,
      cellRenderer: (row) => <div>{row?.data?.payment_intent}</div>
    },
    {
      headerName: 'Reason',
      sortable: false,
      width: 150,
      cellRenderer: (row) => <div className="capitalize">{row?.data?.reason}</div>
    },
    {
      headerName: 'Status',
      sortable: false,
      width: 100,
      cellRenderer: (row) => (
        <div>
          {row?.data?.status && (
            <div className="capitalize">{row?.data?.status.replaceAll('_', ' ')}</div>
          )}
        </div>
      )
    },
    {
      headerName: 'Amount',
      sortable: false,
      width: 100,
      cellRenderer: (row) => (
        <div className="font-bold">$ {(row?.data?.amount / 100).toFixed(2)}</div>
      )
    },
    {
      sortable: false,
      headerName: 'Payment Method',
      width: 150,
      cellRenderer: (row) =>
        row.data?.payment_details?.payment_method && (
          <div>
            {row.data?.payment_details?.payment_method == 'check'
              ? 'Check : **' + row.data?.payment_details?.ach_details?.account_last_4_digit
              : 'Card : **' + row.data?.payment_details?.card_details?.card_last_4_digit}
          </div>
        )
    },
    {
      headerName: 'Respond By',
      sortable: false,
      width: 200,
      cellRenderer: (row) => {
        const isDate = JSON.parse(row?.data?.data)?.evidence_details?.due_by;
        if (!isDate || isDate == '0') return <></>;
        const dueDate = moment.unix(isDate);
        const formattedDueDate = dueDate.format('YYYY-MM-DD');
        const currentDate = moment();
        const daysRemaining = dueDate.diff(currentDate, 'days');
        return (
          <div className="flex justify-center items-center">
            {daysRemaining >= 0 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom">
                    <p>{daysRemaining} days to respond</p>
                  </Tooltip>
                }
              >
                <diV className="flex gap-2 justify-center items-center">
                  <span className="cursor-pointer">
                    <FcInfo size={20} />
                  </span>
                  <p>{formattedDueDate}</p>
                </diV>
              </OverlayTrigger>
            ) : (
              formattedDueDate
            )}
          </div>
        );
      }
    }
  ];
  return (
    <Card className="rounded-xl w-full md:w-[68%]" paddingClass="p-3">
      <h3 className="text-xl font-bold pb-2">Chargeback</h3>
      {/* <div className="ag-theme-alpine" style={{ height: '70vh' }}> */}
      <AgGridTable
        className="h-[70vh] border-2 bg-slate-400"
        //   reference={_this.gridRef}
        rowData={customer.chargeback}
        columnDefs={chargeback_table}
        masterDetail={true}
        fitToScreen={true}
        detailRowHeight={310}
        autoResizeColumn={true}
        stopEditingWhencellsLoseFocus={true}
        border={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20]}
      />
      {/* </div> */}
    </Card>
  );
};

export default Chargeback;
