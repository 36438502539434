import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login, setUploadProgress } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hspp_id } = useParams();
  const params = useLocation();
  // const {
  //   state: { hspp_id }
  // } = params;
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const loggedInUser = useSelector((state) => state.session).userSession;
  const { isUploading } = useSelector((state) => state.uploadProgress);
  const [planList, setPlanList] = useState([]);
  const [patientLocationId, setPatientLocationId] = useState('');
  const [locationList, setLocationList] = useState([]);
  const [subscriptionIdforPlan, setSubscriptionIdforPlan] = useState('');
  const [productInformation, setProductInformation] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState(false);
  const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false);
  const [addSubscriptionModalOpen, setAddSubscriptionModalOpen] = useState(false);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const [addNewSucbscriptionModalOpen, setAddNewSubscriptionModalOpen] = useState(false);
  const [editSubscriptionModalOpen, setEditSubscriptionModalOpen] = useState(false);
  const [addNewPlanModalOpen, setAddNewPlanModalOpen] = useState(false);
  const [subscriptionDetailsModalOpen, setSubscriptionDetailsModalOpen] = useState(false);
  const [achPaymentVisibility, setACHPaymentVisibility] = useState(true);
  const [selectPrimaryPaymentModalOpen, setSelectPrimaryPaymentModalOpen] = useState(false);
  const [selectedSubscriptionDataForEdit, setSelectedSubscriptionDataForEdit] = useState([]);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState({
    payment_method_id: '',
    customer_id: '',
    card_number: '',
    payment_method: ''
  });
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [cardDetails, setCardDetails] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  });
  const [achDetails, setACHDetails] = useState({
    name: '',
    routing_number: '',
    account_number: ''
  });

  const batterySizeOptions = [
    { label: 'None', value: 'None' },
    { label: '675', value: '675' },
    { label: '312', value: '312' },
    { label: '13', value: '13' },
    { label: '10', value: '10' },
    { label: '312 - Rechargable', value: '312 - Rechargable' },
    { label: '13 - Rechargable', value: '13 - Rechargable' },
    { label: 'Lithium Batteries', value: 'Lithium Batteries' }
  ];
  const [noOfPlans, setNoOfPlans] = useState({ value: 1, label: 1 });
  const [refundModalVisibility, setRefundModalVisibility] = useState(false);
  const [refundDetails, setRefundDetails] = useState({
    invoice_id: '',
    amount: '',
    reason: ''
  });
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [fileAttachment, setFileAttachment] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [creditModelOpen, setCreditModelOpen] = useState(false);
  const [creditBalance, setCreditBalance] = useState('');
  const [creditRemarks, setCreditRemarks] = useState('');
  const [credits, setCredits] = useState([]);
  const [refundDuplicateInvoiceModalVisibility, setRefundDuplicateInvoiceModalVisibility] =
    useState(false);
  const [refundDuplicateInvoiceDetails, setRefundDuplicateInvoiceDetails] = useState(null);
  // const [temporaryEditSubscriptionModalOpen, setTemporaryEditSubscriptionModalOpen] =
  //   useState(false);
  const [temporaryEditSubscriptionData, setTemporaryEditSubscriptionData] = useState(null);

  const [deleteSubscriptionModalOpen, setDeleteSubscriptionModalOpen] = useState(false);
  const [deleteSubscriptionData, setDeleteSubscriptionData] = useState(null);

  const [deleteInvoiceModalVisibility, setDeleteInvoiceModalVisibility] = useState(false);
  const [deleteInvoiceId, setDeleteInvoiceId] = useState(null);

  const [editDueDateModalVisibility, setEditDueDateModalVisibility] = useState(false);
  const [editDueDateData, setEditDueDateData] = useState(null);
  const [payModal, setPayModal] = useState(false);
  const [payExtraAmount, setPayExtraAmount] = useState('');
  const [nextDueDate, setNextDueDate] = useState('');
  const [payAdvanceSubscriptionData, setPayAdvanceSubscriptionData] = useState(null);
  const [seeLogEmailDataModalOpen, setSeeLogEmailDataModalOpen] = useState(false);
  const [logEmailData, setLogEmailData] = useState(null);
  const [deletePaymentMethodModalOpen, setDeletePaymentMethodModalOpen] = useState(false);
  const [deletePaymentMethodData, setDeletePaymentMethodData] = useState(null);
  const [logDataToShow, setLogDataToShow] = useState('');
  const [seeLogDataModalOpen, setSeeLogDataModalOpen] = useState(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState('');

  useEffect(() => {
    if (!hspp_id) {
      navigate('/customer-list');
    } else init();
  }, [hspp_id]);

  useEffect(() => {
    if (customerDetails) {
      loadCustomerIdLocation();
      getDocuments();
    }
  }, [customerDetails]);

  useEffect(() => {
    if (patientLocationId != '') {
      autoPopulateAddSubscription();
    }
  }, [patientLocationId]);
  useEffect(() => {
    if (payExtraAmount && payAdvanceSubscriptionData && customerDetails?.invoices) {
      const isPendingInvoice =
        customerDetails.invoices.filter(
          (item) => item.status === 'Pending' && item.subscription == payAdvanceSubscriptionData._id
        ).length > 0;

      if (payAdvanceSubscriptionData?.plan_interval === 'Monthly') {
        const costPerDay = (payAdvanceSubscriptionData?.amount / 12).toFixed(2);
        const months = parseInt(payExtraAmount / payAdvanceSubscriptionData?.amount);
        const remainingAmount = payExtraAmount % payAdvanceSubscriptionData?.amount;
        const days = parseInt(remainingAmount / costPerDay);
        const invoiceStartDate = isPendingInvoice
          ? payAdvanceSubscriptionData.active_invoice_date
          : payAdvanceSubscriptionData.next_invoice_date;
        const nextInvoiceDate = moment(invoiceStartDate)
          .add(months, 'months')
          .add(days, 'days')
          .format('YYYY-MM-DD');
        setNextDueDate(nextInvoiceDate);
      } else if (payAdvanceSubscriptionData?.plan_interval === 'Annually') {
        const costPerDay = (payAdvanceSubscriptionData?.amount / 365).toFixed(2);

        const years = parseInt(payExtraAmount / payAdvanceSubscriptionData?.amount);
        const remainingAmountForMonths = payExtraAmount % payAdvanceSubscriptionData?.amount;
        const months = parseInt(
          remainingAmountForMonths / (payAdvanceSubscriptionData?.amount / 12)
        );
        const remainingAmountForDays =
          remainingAmountForMonths % (payAdvanceSubscriptionData?.amount / 12);
        const days = parseInt(remainingAmountForDays / costPerDay);

        const invoiceStartDate = isPendingInvoice
          ? payAdvanceSubscriptionData.active_invoice_date
          : payAdvanceSubscriptionData.next_invoice_date;

        const nextInvoiceDate = moment(invoiceStartDate)
          .add(years, 'years')
          .add(months, 'months')
          .add(days, 'days')
          .format('YYYY-MM-DD');
        setNextDueDate(nextInvoiceDate);
      }
    }
  }, [payExtraAmount]);
  const init = async () => {
    dispatch(loadingStart());
    loadLocationOptions();
    loadPlanListOptions();
    loadStateOptions();

    await API.getCustomerDetailsById(hspp_id)
      .then((response) => {
        if (response) {
          setCustomerDetails(response);
          // getCredits(response._id);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const loadStateOptions = async () => {
    await API.getCountryStates()
      .then(async (response) => {
        if (response) {
          const list = response.states.map((item) => ({
            label: item.subdivisionIsoCode,
            value: item.subdivisionIsoCode
          }));
          setStateList(list);
        }
      })
      .finally(() => {});
  };

  const loadCustomerIdLocation = () => {
    API.getCustomerById(customerDetails?.customer_id).then((response) => {
      if (response.status) {
        setPatientLocationId(response.result[0].location);
      }
    });
    // .then((response) => {
    //   autoPopulateAddSubscription();
    // });
  };
  const loadPlanListOptions = async () => {
    await API.getPlansData()
      .then((response) => {
        if (response) {
          const list = response.map((item) => ({
            label: item.name,
            value: item
          }));
          setPlanList(list);
        }
      })
      .finally(() => {});
  };

  const loadLocationOptions = async () => {
    let response = await API.getFranchiseLocation();
    if (response) {
      const list = response.map((item) => ({
        label: item.locationName,
        value: item
      }));
      setLocationList(list);
    }
  };

  const calculateNumberOfMonthsOrYears = (productInfo) => {
    if (
      productInfo &&
      [
        '2022protectionsupplementallossdamage',
        'protectionsupplementallossdamagesingleaid'
      ].includes(productInfo?.plan?.value?.plan_code) &&
      (productInfo.l_warranty_expiry || productInfo.r_warranty_expiry) &&
      productInfo.plan_interval
    ) {
      // Calculate frequency based on warrenty date
      const frequencyInMonth = moment(
        moment(productInfo.l_warranty_expiry || productInfo.r_warranty_expiry)
      ).diff(moment().subtract(1, 'day'), 'months');
      if (productInfo.plan_interval?.value == 'Monthly') return frequencyInMonth;
      else if (productInfo.plan_interval?.value == 'Annually')
        return Math.ceil(frequencyInMonth / 12);
      else if (productInfo.plan_interval?.value == 'Full Amount') return '0';
    } else if (
      productInfo &&
      ['2022secureplan', '2022secureplansupplementalrepairwarrantysingleaid'].includes(
        productInfo?.plan?.value?.plan_code
      ) &&
      productInfo.plan_interval
    ) {
      // Calculate frequency based on db frequency set
      const frequencyInMonth = productInfo?.plan?.value?.frequency * 12;
      if (productInfo.plan_interval?.value == 'Monthly') return frequencyInMonth;
      else if (productInfo.plan_interval?.value == 'Annually')
        return Math.ceil(frequencyInMonth / 12);
      else if (productInfo.plan_interval?.value == 'Full Amount') return '0';
    }
    return '';
  };

  const filteredPlanList = (index) => {
    const productInfo = productInformation[index];
    let newPlanList = planList.filter((item) =>
      ['batteryplan', 'secureplusplansupplementalrepairwarranty'].includes(item.value.plan_code)
    );
    if (productInfo && productInfo.l_warranty_expiry && productInfo.r_warranty_expiry) {
      newPlanList = planList.filter((item) =>
        [
          'batteryplan',
          'secureplusplansupplementalrepairwarranty',
          '2022secureplan',
          '2022protectionsupplementallossdamage'
        ].includes(item.value.plan_code)
      );
    } else if (
      (productInfo && productInfo.l_warranty_expiry && productInfo.r_warranty_expiry == '') ||
      (productInfo && productInfo.l_warranty_expiry == '' && productInfo.r_warranty_expiry)
    ) {
      newPlanList = planList.filter((item) =>
        [
          'batteryplan',
          'secureplusplansupplementalrepairwarranty',
          '2022secureplansupplementalrepairwarrantysingleaid',
          'protectionsupplementallossdamagesingleaid'
        ].includes(item.value.plan_code)
      );
    }

    return newPlanList;
  };

  const calculateTotalAmount = () => {
    let amount = 0;
    for (const subscription of productInformation) {
      if (subscription?.plan_interval?.value && subscription?.plan?.value) {
        const interval = subscription?.plan_interval?.value;
        if (interval == 'monthly') amount += subscription?.plan?.value.monthly;
        else if (interval == 'annually') amount += subscription?.plan?.value.annually;
      }
    }
    return amount;
  };

  const updateCardDetails = (key, value) => {
    setCardDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
    setCardDetails((state) => {
      return {
        ...state,
        focused: key
      };
    });
  };

  const updateACHDetails = (key, value) => {
    setACHDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  const autoPopulateAddSubscription = () => {
    const temp = [...productInformation];

    //updating location for patients location
    const location = locationList.filter((item) => item.value.locationId == patientLocationId);
    if (location.length > 0) {
      for (let index = 0; index < noOfPlans.value; index++) {
        temp[index].location = location[0];
      }
    }

    // set default payment date
    for (let index = 0; index < noOfPlans.value; index++) {
      temp[index].payment_date = moment().toDate();
    }

    // updating location for patients location
    const created_at_location = locationList.filter(
      (item) => item.value.locationName == loggedInUser?.location[0]
    );
    if (created_at_location.length > 0 || !loggedInUser.location || loggedInUser.location == !'All')
      for (let index = 0; index < noOfPlans.value; index++) {
        temp[index].created_at_location = created_at_location[0];
      }

    setProductInformation(temp);
  };

  const updateProductInformation = (key, value, index) => {
    let c = [...productInformation];
    if (key == 'payment_date' && moment(value) < moment().startOf('day')) return;

    c[index] = { ...c[index], [key]: value };

    // Add same date to r_warranty
    if (key === 'l_warranty_expiry' && value != '')
      c[index] = { ...c[index], r_warranty_expiry: value };
    // Add same battery size to r_battery_size
    if (key === 'l_battery_size') c[index] = { ...c[index], r_battery_size: value };

    if (key === 'l_battery_size' || key === 'r_battery_size') {
      let chargableOptions = new Set(['675', '312', '13', '10']);
      let left = c[index].l_battery_size.value,
        right = c[index].r_battery_size.value;

      if (chargableOptions.has(left) && chargableOptions.has(right)) {
        c[index] = { ...c[index], chargable: { label: 'No', value: 'No' } };
      } else {
        c[index] = { ...c[index], chargable: { label: 'Yes', value: 'Yes' } };
      }
    }

    if (key == 'l_warranty_expiry' || key == 'r_warranty_expiry') {
      // Reset selected plan list if warranty date is changed
      c[index] = { ...c[index], plan: '' };
    }

    if (
      key == 'l_warranty_expiry' ||
      key == 'r_warranty_expiry' ||
      key == 'plan' ||
      key == 'plan_interval'
    ) {
      // Recalculate frequency
      c[index] = { ...c[index], plan_frequency: calculateNumberOfMonthsOrYears(c[index]) };
    }

    // Change newsletter accordingly (PREVIOUS CODE)
    // if (key === 'l_battery_size' || key === 'r_battery_size') {
    //   if (['Lithium Batteries', '13 - Rechargable', '312 - Rechargable'].includes(value.value))
    //     c[index] = { ...c[index], newsletter: false };
    //   else c[index] = { ...c[index], newsletter: true };
    // }

    // Change newsletter accordingly
    if (key === 'l_battery_size' || key === 'r_battery_size') {
      if (
        ['Lithium Batteries', 'None'].includes(c[index].l_battery_size.value) ||
        ['Lithium Batteries', 'None'].includes(c[index].r_battery_size.value)
      )
        c[index] = { ...c[index], newsletter: true };
      else c[index] = { ...c[index], newsletter: false };
    }

    setProductInformation(c);
  };

  const updateSubscriptionInformation = (key, value) => {
    setSelectedSubscriptionDataForEdit(() => {
      return {
        ...selectedSubscriptionDataForEdit,
        // signature: `${customerDetails?.first_name} ${customerDetails?.last_name}`,
        [key]: value
      };
    });
    // console.log(selectedSubscriptionDataForEdit);
  };

  const validateValues = (fields = [], arr) => {
    for (const field of fields) {
      if (!arr[field]) return false;
    }
    return true;
  };

  //Payload for Subscription Creation
  const finalDataSubmitforSubscription = () => {
    const subscriptions = [];
    for (const item of productInformation) {
      const product_information = {
        ...item,
        chargable: item.chargable?.value,
        location: item.location?.value,
        created_at_location: item.created_at_location?.value,
        location_id: item.location?.value?.code,
        plan_interval: item.plan_interval?.value,
        plan: item.plan?.value,
        l_warranty_expiry: item.l_warranty_expiry,
        r_warranty_expiry: item.r_warranty_expiry,
        l_battery_size: item.l_battery_size?.value,
        r_battery_size: item.r_battery_size?.value,
        payment_date: item.payment_date,
        plan_override_amount: item.plan_override_amount || null,
        plan_override_date: item.plan_override_date || null
      };
      if (
        !validateValues(
          [
            product_information.l_warranty_expiry === ''
              ? `r_warranty_expiry`
              : 'l_warranty_expiry',
            'plan',
            'plan_interval',
            'chargable',
            'location',
            'created_at_location',
            'l_battery_size',
            'r_battery_size',
            'payment_date'
          ],
          product_information
        )
      )
        return toast.error('Required subscription detail fields cannot be blank!');

      subscriptions.push(product_information);
    }

    return createSubscription(subscriptions);
  };

  //Payload for Plan Creation
  const finalDataSubmitforPlan = () => {
    const plans = [];
    for (const item of productInformation) {
      const plans_information = {
        ...item,
        plan_interval: item.plan_interval?.value,
        plan: item.plan?.value
      };
      if (!validateValues(['l_warranty_expiry', 'plan', 'plan_interval'], plans_information))
        return toast.error('Required plan detail fields cannot be blank!');

      plans.push(plans_information);
    }
    return addPlans(plans);
  };

  //payload for Add New Payment
  const finalDataSubmitforAddNewPayment = () => {
    const card_details = { ...cardDetails, number: cardDetails?.number.toString() };
    const ach_details = { ...achDetails };

    if (
      !validateValues(['cvc', 'expiry', 'name', 'number'], card_details) &&
      !validateValues(['name', 'account_number', 'routing_number'], ach_details)
    )
      return toast.error('Required payment detail fields cannot be blank!');

    const card_temp = cardDetails.expiry.split('/');
    const finalPaymentData = {
      customer_id: hspp_id,
      card_details: {
        card_last_4_digit: card_details.number.substring(card_details.number.length - 4),
        expiry_month: card_temp[0],
        expiry_year: card_temp[1],
        expiry_date: cardDetails.expiry,
        name: card_details.name,
        cvv: cardDetails.cvc,
        card_number: cardDetails.number
      },
      ach_details: {
        account_last_4_digit: ach_details.account_number.substring(
          ach_details.account_number.length - 4
        ),
        account_number: ach_details.account_number,
        name: ach_details.name,
        routing_number: ach_details.routing_number
      }
      // stripe_customer_id: stripeCustomerId
    };

    return addPaymentMethod(finalPaymentData);
  };

  const handleRefundDetails = (refund_details) => {
    setRefundModalVisibility(true);
    setRefundDetails((prev) => ({
      ...prev,
      invoice_id: refund_details._id,
      amount: refund_details.amount,
      reason: 'N/A'
    }));
  };

  const handleRefundDuplicateInvoiceDetails = (refund_details) => {
    setRefundDuplicateInvoiceModalVisibility(true);
    setRefundDuplicateInvoiceDetails((prev) => ({
      ...prev,
      amount: refund_details.amount,
      reason: 'Initiating duplicate invoice refund',
      payment_gateway_transaction_id: refund_details.payment_gateway_transaction_id,
      invoice_id: refund_details._id
    }));
  };

  const handleDeleteInvoices = (invoice_id) => {
    setDeleteInvoiceModalVisibility(true);
    setDeleteInvoiceId(invoice_id);
  };
  //add Payment method api call
  const addPaymentMethod = async (paymentData) => {
    dispatch(loadingStart());
    await API.addNewPayment(paymentData)
      .then((response) => {
        if (response) {
          toast.success('New Payment Method Added!');
          setAddPaymentModalOpen(false);
          init();
        }
      })
      .finally(() => dispatch(loadingStop()));
  };
  //Add plan API Call
  const addPlans = async (plans) => {
    dispatch(loadingStart());
    await API.addPlans({
      plans: plans,
      hspp_id: hspp_id,
      subscription_id: subscriptionIdforPlan
    })
      .then((response) => {
        if (response) {
          toast.success('New Plan Added!');
          // setHsppId(response.hspp_id);
          // payInvoice(response?.invoice_ids);
        } else {
          dispatch(loadingStop());
        }
      })
      .finally(() => {
        init();
        dispatch(loadingStop());
        setAddNewPlanModalOpen(false);
      });
  };

  //Add subscription API Call
  const createSubscription = async (subscriptions) => {
    const id = customerDetails._id;
    const subscription = subscriptions[0];

    dispatch(loadingStart());
    await API.createSubscription({ subscription: subscription, id: id })
      .then((response) => {
        if (response) {
          toast.success('New Subscription Added!');
          // setHsppId(response.hspp_id);
          // navigate('/customer-info', { state: { hspp_id: response?.hspp_id } });
          init();
          setAddNewSubscriptionModalOpen(false);
        } else {
          dispatch(loadingStop());
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  //Update Customer details
  const updateDetails = (key, hspp_id, customerDetails) => {
    dispatch(loadingStart());
    API.updateCustomerDetails({ key: key, hspp_id: hspp_id, data: customerDetails })
      .then((response) => {
        if (response) {
          setEditCustomerModalOpen(false);
          toast.success('Customer Successfully Updated!');
        }
      })
      .finally(() => {
        init();
        dispatch(loadingStop());
      });
  };

  //Update Customer billing details
  const updatePaymentDetails = async (hspp_id, paymentDetails) => {
    dispatch(loadingStart());
    await API.updateBillingAddress({ hspp_id: hspp_id, data: paymentDetails })
      .then((response) => {
        if (response) {
          setEditPaymentModalOpen(false);
        }
      })
      .finally(() => {
        init();
        dispatch(loadingStop());
        toast.success('Billing Details Successfully Updated!');
      });
  };

  //Add Notes to Customer info page API Call
  const addNote = async (note) => {
    dispatch(loadingStart());
    await API.addNote(customerDetails._id, note)
      .then((response) => {
        if (response) {
          toast.success('Note has been added!');
          setAddNoteModalOpen(false);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  //Calcel A Subscription API Call, Direct for Admin, Ticket for employee
  const cancelSubscription = async (id) => {
    const isAdmin = loggedInUser.permissions.find((p) => p == 'cancel_subscription');

    dispatch(loadingStart());
    if (isAdmin) {
      await API.cancelSubscription(id)
        .then((response) => {
          if (response) {
            toast.success('Subscription is cancelled successfully.');
          }
          init();
        })
        .finally(() => {
          setSubscriptionDetailsModalOpen(false);
          setSubscriptionDetails(null);
          dispatch(loadingStop());
        });
    } else {
      await API.createTicket({
        action: 'cancelPlan',
        subscription_id: id,
        customer_id: customerDetails._id
      })
        .then((response) => {
          if (response) {
            toast.success('Ticket has been created for cancelation');
          }
        })
        .finally(() => {
          setSubscriptionDetailsModalOpen(false);
          setSubscriptionDetails(null);
          dispatch(loadingStop());
        });
    }
  };

  //Select Primary Payment Method
  const selectPrimaryPaymentMethod = async () => {
    const data = {
      customer_id: selectedPaymentDetails.customer_id,
      payment_method_id: selectedPaymentDetails.payment_method_id
    };
    dispatch(loadingStart());
    await API.selectPrimaryPaymentMethod(data)
      .then((response) => {
        if (response) {
          setSelectPrimaryPaymentModalOpen(false);
          init();
          toast.success('Payment method updated successfully');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const handleRetryPayment = async (Id) => {
    dispatch(loadingStart());
    await API.retryPayment(Id)
      .then((response) => {
        if (response) {
          toast.success(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  //Initiate a refund
  const initiateRefund = async (data) => {
    dispatch(loadingStart());
    await API.initiateRefund(data)
      .then((response) => {
        if (response) {
          toast.success('Refund Initiated Successfully!');
          setRefundModalVisibility(false);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  const initiateDuplicateInvoiceRefund = async () => {
    dispatch(loadingStart());
    await API.initiateDuplicateInvoiceRefund(refundDuplicateInvoiceDetails)
      .then((response) => {
        if (response) {
          toast.success('Refund Initiated Successfully!');
        }
      })
      .finally(() => {
        setRefundDuplicateInvoiceModalVisibility(false);
        dispatch(loadingStop());
        init();
      });
  };

  const handelEditSubscription = async () => {
    dispatch(loadingStart());
    await API.EditSubscription(selectedSubscriptionDataForEdit)
      .then((response) => {
        if (response) {
          toast.success('Subscription Updated.');
          setEditSubscriptionModalOpen(false);
          setSelectedSubscriptionDataForEdit(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  const getDocuments = async () => {
    const data = {
      id: customerDetails._id
    };

    await API.GetDocuments(data).then((response) => {
      setDocuments(response);
    });
  };

  const onUploadProgress = (event) => {
    const calc = Math.round((event.loaded * 100) / event.total);
    if (calc) dispatch(setUploadProgress(true));
  };

  const handelChatMessageSend = async () => {
    if (!fileAttachment) toast.error('Please Select Attachment !');
    else {
      const data = {
        customer_id: customerDetails?._id,
        message: userMessage,
        file: fileAttachment
      };

      setChatModalOpen(false);
      toast.info('Document upload is in progress..');

      API.SendDocuments(data, onUploadProgress)
        .then(() => {
          setUserMessage('');
          setFileAttachment(null);
          setUserMessage('');
          getDocuments();
          toast.success('Document upload completed..!');
        })
        .finally(() => {
          dispatch(setUploadProgress(false));
        });
    }
  };

  const handelAddCredits = async () => {
    if (creditBalance == '') {
      toast.error('Please Enter The Amount !');
    } else {
      dispatch(loadingStart());
      const data = {
        customerId: customerDetails?._id,
        creditBalance,
        creditRemarks
      };
      const res = await API.AddCredits(data);
      if (res) {
        toast.success(
          creditBalance > 0
            ? '$' + creditBalance + ' added successfully !'
            : '$' + creditBalance * -1 + ' deducted successfully !'
        );
        setCreditModelOpen(false);
        setCreditBalance('');
        setCreditRemarks('');
        dispatch(loadingStop());
        init();
      }
    }
  };

  const getCredits = async (id) => {
    const res = await API.GetCredits({ id: customerDetails?._id || id });
    setCredits(res);
    setCreditModelOpen(false);
  };

  const handelTemporaryEditSubscriptionSubmit = async () => {
    const temp = { ...temporaryEditSubscriptionData };
    temp.status = temporaryEditSubscriptionData.status.value;

    dispatch(loadingStart());
    await API.temporaryEditSubscription(temp)
      .then((response) => {
        if (response) {
          toast.success('Subscription Updated.');
          // setTemporaryEditSubscriptionModalOpen(false);
          setEditSubscriptionModalOpen(false);
          setTemporaryEditSubscriptionData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  const handleDeleteSubscription = async () => {
    dispatch(loadingStart());
    await API.deleteSubscription(deleteSubscriptionData._id)
      .then((response) => {
        if (response) {
          toast.success('Subscription Deleted Successfully.');
          setDeleteSubscriptionModalOpen(false);
          setDeleteSubscriptionData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  const handleDeleteInvoiceSubmit = async () => {
    dispatch(loadingStart());
    await API.deleteInvoice(deleteInvoiceId)
      .then((response) => {
        if (response) {
          toast.success('Invoice Deleted Successfully.');
          setDeleteInvoiceModalVisibility(false);
          setDeleteInvoiceId(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  const handleDueDateEdit = (_id, due_date) => {
    setEditDueDateModalVisibility(true);
    setEditDueDateData((prev) => ({ ...prev, _id: _id, due_date: moment(due_date).toDate() }));
  };

  const handleEditDueDateSubmit = async () => {
    if (editDueDateData.due_date == null) return toast.error('Due date cannot be blank.');
    dispatch(loadingStart());
    await API.editInvoiceDueDate(editDueDateData)
      .then((response) => {
        if (response) {
          toast.success('Due Date Updated Successfully.');
          setEditDueDateModalVisibility(false);
          setEditDueDateData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };
  const handelPayExtra = () => {
    if (
      moment(nextDueDate).format('YYYY-MM-DD') >
      moment(payAdvanceSubscriptionData?.expiry_date).format('YYYY-MM-DD')
    ) {
      toast.error('Due Date Cant be grater than expiry date.');
      return;
    }
    const data = {
      advancePaymentAmount: payExtraAmount,
      nextInvoiceDate: nextDueDate,
      customerId: customerDetails?._id,
      subscriptionDetail: payAdvanceSubscriptionData
    };

    dispatch(loadingStart());
    API.payAdvanceInvoice(data)
      .then((res) => {
        if (res) {
          toast.success('Success.');
        }
      })
      .finally(() => {
        setPayExtraAmount('');
        setNextDueDate('');
        setPayAdvanceSubscriptionData(null);
        setPayModal(false);
        dispatch(loadingStop());
        init();
      });
  };

  const handleDeletePaymentMethod = async () => {
    dispatch(loadingStart());
    await API.deletePaymentMethod(deletePaymentMethodData._id)
      .then((response) => {
        if (response) {
          toast.success('Payment method deleted Successfully.');
          setDeletePaymentMethodModalOpen(false);
          setDeletePaymentMethodData(null);
          init();
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const handleDeleteDocumentSubmit = async () => {
    dispatch(loadingStart());
    await API.DeleteDocument(deleteDocumentId)
      .then((response) => {
        if (response) {
          toast.success('Docuement Deleted Successfully!');
        }
      })
      .finally(() => {
        setDeleteDocumentModal(false);
        setDeleteDocumentId('');
        dispatch(loadingStop());
        init();
      });
  };

  const sendCustomerPassword = async (user_id) => {
    dispatch(loadingStart());
    await API.SendCustomerPassword(user_id)
      .then((response) => {
        if (response) {
          toast.success("Customer's password updated Successfully!");
        }
      })
      .finally(() => {
        init();
      });
  };

  const optOutFromMonthlyNewsletterEmail = async (customer_id, checked) => {
    dispatch(loadingStart());
    await API.OptOutFromMonthlyNewsletterEmail(customer_id, checked)
      .then((response) => {
        if (response) {
          toast.success('Customer updated successfully.');
        }
      })
      .finally(() => {
        init();
      });
  };

  return (
    <DashboardContainer>
      {customerDetails && (
        <Body
          _this={{
            customerDetails,
            editCustomerModalOpen,
            setEditCustomerModalOpen,
            editPaymentModalOpen,
            setEditPaymentModalOpen,
            addNoteModalOpen,
            setAddNoteModalOpen,
            subscriptionDetailsModalOpen,
            setSubscriptionDetailsModalOpen,
            addSubscriptionModalOpen,
            setAddSubscriptionModalOpen,
            setAddNewSubscriptionModalOpen,
            addNewSucbscriptionModalOpen,
            setAddNewPlanModalOpen,
            addNewPlanModalOpen,
            subscriptionDetails,
            setSubscriptionDetails,
            cancelSubscription,
            updateDetails,
            stateList,
            addNote,
            updatePaymentDetails,
            planList,
            locationList,
            productInformation,
            setProductInformation,
            noOfPlans,
            updateProductInformation,
            finalDataSubmitforSubscription,
            calculateTotalAmount,
            filteredPlanList,
            batterySizeOptions,
            setNoOfPlans,
            setSubscriptionIdforPlan,
            subscriptionIdforPlan,
            finalDataSubmitforPlan,
            addPaymentModalOpen,
            setAddPaymentModalOpen,
            achPaymentVisibility,
            setACHPaymentVisibility,
            updateCardDetails,
            updateACHDetails,
            cardDetails,
            achDetails,
            finalDataSubmitforAddNewPayment,
            selectPrimaryPaymentModalOpen,
            setSelectPrimaryPaymentModalOpen,
            selectedPaymentDetails,
            setSelectedPaymentDetails,
            selectPrimaryPaymentMethod,
            handleRetryPayment,
            handleRefundDetails,
            refundModalVisibility,
            setRefundModalVisibility,
            refundDetails,
            setRefundDetails,
            initiateRefund,
            loggedInUser,
            editSubscriptionModalOpen,
            setEditSubscriptionModalOpen,
            selectedSubscriptionDataForEdit,
            setSelectedSubscriptionDataForEdit,
            updateSubscriptionInformation,
            handelEditSubscription,
            stripeCustomerId,
            setStripeCustomerId,
            chatModalOpen,
            setChatModalOpen,
            userMessage,
            setUserMessage,
            fileAttachment,
            setFileAttachment,
            handelChatMessageSend,
            // getDocuments,
            isUploading,
            documents,
            setDocuments,
            creditModelOpen,
            setCreditModelOpen,
            creditBalance,
            setCreditBalance,
            creditRemarks,
            setCreditRemarks,
            handelAddCredits,
            credits,
            setCredits,
            refundDuplicateInvoiceModalVisibility,
            setRefundDuplicateInvoiceModalVisibility,
            refundDuplicateInvoiceDetails,
            setRefundDuplicateInvoiceDetails,
            handleRefundDuplicateInvoiceDetails,
            initiateDuplicateInvoiceRefund,
            // temporaryEditSubscriptionModalOpen,
            // setTemporaryEditSubscriptionModalOpen,
            temporaryEditSubscriptionData,
            setTemporaryEditSubscriptionData,
            handelTemporaryEditSubscriptionSubmit,
            deleteSubscriptionModalOpen,
            setDeleteSubscriptionModalOpen,
            deleteSubscriptionData,
            setDeleteSubscriptionData,
            handleDeleteSubscription,
            handleDeleteInvoices,
            deleteInvoiceModalVisibility,
            setDeleteInvoiceModalVisibility,
            deleteInvoiceId,
            setDeleteInvoiceId,
            handleDeleteInvoiceSubmit,
            handleDueDateEdit,
            editDueDateModalVisibility,
            setEditDueDateModalVisibility,
            editDueDateData,
            setEditDueDateData,
            handleEditDueDateSubmit,
            payModal,
            setPayModal,
            payExtraAmount,
            setPayExtraAmount,
            nextDueDate,
            setNextDueDate,
            handelPayExtra,
            payAdvanceSubscriptionData,
            setPayAdvanceSubscriptionData,
            seeLogEmailDataModalOpen,
            setSeeLogEmailDataModalOpen,
            logEmailData,
            setLogEmailData,
            deletePaymentMethodModalOpen,
            setDeletePaymentMethodModalOpen,
            deletePaymentMethodData,
            setDeletePaymentMethodData,
            handleDeletePaymentMethod,
            logDataToShow,
            setLogDataToShow,
            setSeeLogDataModalOpen,
            seeLogDataModalOpen,
            deleteDocumentModal,
            setDeleteDocumentModal,
            deleteDocumentId,
            setDeleteDocumentId,
            handleDeleteDocumentSubmit,
            sendCustomerPassword,
            optOutFromMonthlyNewsletterEmail
          }}
        />
      )}
    </DashboardContainer>
  );
};

export default Index;
